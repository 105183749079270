import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import HexagramDisplay from '../components/HexagramDisplay';
import { earlyHeavenTrigrams } from '../data/earlyHeavenTrigrams';

type TrigramBinary = keyof typeof earlyHeavenTrigrams;

const EarlyHeavenTrigramDetail: FC = () => {
  const navigate = useNavigate();
  const { binary } = useParams<{ binary: string }>();

  if (!binary || !earlyHeavenTrigrams[binary as TrigramBinary]) {
    return <div>卦象不存在</div>;
  }

  const trigram = earlyHeavenTrigrams[binary as TrigramBinary];

  return (
    <div className="max-w-lg mx-auto py-6 px-4">
      <button
        onClick={() => navigate(-1)}
        className="mb-6 text-red-600 hover:text-red-700 flex items-center"
      >
        <ArrowLeftIcon className="w-5 h-5 mr-1" />
        返回
      </button>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-center mb-8">
          <div className="text-center">
            <HexagramDisplay binary={binary} showThreeLines={true} />
            <h1 className="text-2xl font-bold text-gray-900 mt-4">
              {trigram.name}
            </h1>
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">卦取象</h2>
            <p className="text-gray-600">{trigram.symbol}</p>
          </div>

          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">卦性</h2>
            <p className="text-gray-600">{trigram.nature}</p>
          </div>

          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">万物属类</h2>
            <p className="text-gray-600 leading-relaxed">{trigram.correspondence}</p>
          </div>

          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">卦数</h2>
            <p className="text-gray-600">{trigram.number}</p>
          </div>

          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">卦五行</h2>
            <p className="text-gray-600">{trigram.element}</p>
          </div>

          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">卦象歌</h2>
            <p className="text-gray-600">{trigram.poem}</p>
          </div>

          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">旺季/月</h2>
            <p className="text-gray-600">{trigram.energy}</p>
          </div>

          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">衰季/月</h2>
            <p className="text-gray-600">{trigram.waning}</p>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default EarlyHeavenTrigramDetail;
