import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Article } from '../types/article';

interface ArticleState {
  articles: Article[];
  addArticle: (article: Article) => void;
  updateArticle: (id: string, article: Partial<Article>) => void;
  deleteArticle: (id: string) => void;
}

export const useArticleStore = create<ArticleState>()(
  persist(
    (set) => ({
      articles: [],
      addArticle: (article) => 
        set((state) => ({ articles: [...state.articles, article] })),
      updateArticle: (id, article) =>
        set((state) => ({
          articles: state.articles.map((a) =>
            a.id === id ? { ...a, ...article } : a
          ),
        })),
      deleteArticle: (id) =>
        set((state) => ({
          articles: state.articles.filter((a) => a.id !== id),
        })),
    }),
    {
      name: 'article-storage',
    }
  )
);