// 先天八卦数据（按传统顺序排列）
export const orderedTrigrams = ['111', '011', '101', '001', '110', '010', '100', '000'] as const;

export type TrigramBinary = typeof orderedTrigrams[number];

export const earlyHeavenTrigrams: Record<TrigramBinary, {
  name: string;
  number: string;
  element: string;
  poem: string;
  nature: string;
  correspondence: string;
  symbol: string;
  energy: string;
  waning: string;
}> = {
  '111': {
    name: '乾',
    number: '1',
    element: '金',
    poem: '乾三连',
    nature: '健',
    correspondence: '天、君、父、老人、官贵、头、骨、马、金宝、珠玉、水果、圆物、冠、镜、刚物、大赤色、水寒',
    symbol: '乾为天',
    energy: '秋',
    waning: '夏'
  },
  '011': {
    name: '兑',
    number: '2',
    element: '金',
    poem: '兑上缺',
    nature: '说',
    correspondence: '泽、少女、巫、舌、妾、肺、羊、毁折之物、带口之器、属金者、废缺之物、奴仆、婢、白色',
    symbol: '兑为泽',
    energy: '秋',
    waning: '夏'
  },
  '101': {
    name: '离',
    number: '3',
    element: '火',
    poem: '离中虚',
    nature: '丽',
    correspondence: '火、雉、日、目、电、霓霞、中女、甲胄、兵、文书、槁木、炉、兽、鳄龟蟹蚌、凡有壳之物、红赤紫色、花纹人、干燥物、亦色、心',
    symbol: '离为火',
    energy: '夏',
    waning: '冬'
  },
  '001': {
    name: '震',
    number: '4',
    element: '木',
    poem: '震仰盂',
    nature: '动',
    correspondence: '雷、长男、足、发、龙、百虫、蹄、竹、萑苇、马鸣、母足、颡、稼、乐器之类、草木、青碧绿色、树、木核、柴、蛇',
    symbol: '震为雷',
    energy: '春',
    waning: '秋'
  },
  '110': {
    name: '巽',
    number: '5',
    element: '木',
    poem: '巽下断',
    nature: '入',
    correspondence: '风、长女、僧尼、鸡、股、百禽、百草、臼、香气、臭、绳、眼、羽毛、帆、扇、枝叶之类、仙道工匠、直物、工巧之器、青碧绿色',
    symbol: '巽为风',
    energy: '春',
    waning: '秋'
  },
  '010': {
    name: '坎',
    number: '6',
    element: '水',
    poem: '坎中满',
    nature: '陷',
    correspondence: '水、雨、雪、工、豕、中男、沟渎、弓轮、耳、血、月、盗、宫律、栋、丛棘、狐、蒺藜、桎梏、水族、鱼、盐、酒、醢、有核之物、黑色',
    symbol: '坎为水',
    energy: '冬',
    waning: '辰戌丑未月'
  },
  '100': {
    name: '艮',
    number: '7',
    element: '土',
    poem: '艮覆碗',
    nature: '止',
    correspondence: '山、土、少男、童子、狗、手指、径路、门阙、果、瓜、阉寺、鼠、虎、狐、黔喙之属、木生之物、藤生之物、爪、鼻、黄色',
    symbol: '艮为山',
    energy: '辰戌丑未月',
    waning: '春'
  },
  '000': {
    name: '坤',
    number: '8',
    element: '土',
    poem: '坤六断',
    nature: '顺',
    correspondence: '地、母、老妇、土、牛、金、布帛、文章、辇、方物、柄、黄色、瓦器、腹、裳、黑色、黍稷、书、米、谷',
    symbol: '坤为地',
    energy: '辰戌丑未月',
    waning: '春'
  }
};