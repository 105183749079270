import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useDivinationStore } from './divinationStore';
import * as authApi from '../api/auth';
import * as divinationApi from '../api/divination';
import { logger } from '../utils/clientLogger';
import type { User } from '../types/auth';

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
  sessionExpiry: number | null;
  login: (username: string, password: string) => Promise<boolean>;
  register: (username: string, password: string, confirmPassword: string, invitationCode: string) => Promise<boolean>;
  logout: () => void;
  updateSession: () => void;
  updateUserAvatar: (avatarUrl: string) => void;
}

// Session duration in milliseconds (30 days)
const SESSION_DURATION = 24 * 60 * 60 * 1000 * 30;

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      isAuthenticated: false,
      user: null,
      token: null,
      sessionExpiry: null,

      login: async (username: string, password: string) => {
        try {
          logger.info('Attempting login', { username });
          const response = await authApi.login(username, password);
          const sessionExpiry = Date.now() + SESSION_DURATION;
          
          // Set auth token for API requests
          divinationApi.setAuthToken(response.token);
          
          set({ 
            isAuthenticated: true, 
            user: {
              id: 0,
              username: response.user.username,
              role: response.user.role,
              avatarUrl: response.user.avatarUrl
            },
            token: response.token,
            sessionExpiry
          });

          // Sync divination results after successful login
          const divinationStore = useDivinationStore.getState();
          await divinationStore.syncWithServer();
          
          logger.info('Login successful', { username });
          return true;
        } catch (error) {
          logger.error('Login failed', error);
          return false;
        }
      },

      register: async (username: string, password: string, confirmPassword: string, invitationCode: string) => {
        try {
          logger.info('Attempting registration', { username });
          const response = await authApi.register(username, password, confirmPassword, invitationCode);
          const sessionExpiry = Date.now() + SESSION_DURATION;
          
          // Set auth token for API requests
          divinationApi.setAuthToken(response.token);
          
          set({ 
            isAuthenticated: true, 
            user: {
              id: 0,
              username: response.user.username,
              role: response.user.role,
              avatarUrl: response.user.avatarUrl
            },
            token: response.token,
            sessionExpiry
          });
          
          logger.info('Registration successful', { username });
          return true;
        } catch (error) {
          logger.error('Registration failed', error);
          return false;
        }
      },

      logout: () => {
        const { user } = get();
        logger.info('User logging out', { username: user?.username });
        
        // Clear auth token
        divinationApi.setAuthToken(null);
        
        // Clear divination store state
        const resetDivinationStore = useDivinationStore.getState().reset;
        resetDivinationStore();
        
        // Clear auth state
        set({ 
          isAuthenticated: false, 
          user: null,
          token: null,
          sessionExpiry: null
        });
      },

      updateSession: () => {
        const { isAuthenticated, user, token } = get();
        if (isAuthenticated) {
          logger.info('Updating session', { username: user?.username });
          // Refresh auth token in API client
          divinationApi.setAuthToken(token);
          set({ sessionExpiry: Date.now() + SESSION_DURATION });
        }
      },

      updateUserAvatar: (avatarUrl: string) => {
        const { user } = get();
        if (user) {
          set({
            user: {
              ...user,
              avatarUrl
            }
          });
        }
      }
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ 
        isAuthenticated: state.isAuthenticated, 
        user: state.user,
        token: state.token,
        sessionExpiry: state.sessionExpiry
      }),
    }
  )
);