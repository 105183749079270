export const academyQuotes = [
  "生命要的是进化，不是进步",
  "反者，道之动也",
  "易者，变化之谓也",
  "停止思考和想象即是当下解脱",
  "心没有一秒不在期待结果发生，期待带来忧患",
  "当下即是内观",
  "约束心灵的变化叫做瑜伽",
  "外在努力，内在明心见性",
  "真正焦虑感的消失来源于内心的笃定和约束",
  "冥想是清理内心垃圾的有效方式",
  "停止想象和思考，通过真实的观察自己，成长就是一瞬间",
  "天道酬勤，地道酬善",
  "知命不惧，知义不苟",
  "冥想不是空想和想象",
  "所有知识都不是学来的",
  "观只在当下",
  "知识的来源不是学习来的，是创造来的",
  "当过去、现在、未来的念头集中在一个事物上的时候就是冥想",
  "思考：是当下的意识",
  "想象：假想的一种现象（想象的东西都是不真实的）",
  "当下一秒不期待即是心灵解脱"
];

export function getRandomQuote(): string {
  const randomIndex = Math.floor(Math.random() * academyQuotes.length);
  return academyQuotes[randomIndex];
}