import { logger } from './clientLogger';

interface AsyncOperation {
  id: string;
  operation: () => Promise<void>;
  retries: number;
  maxRetries: number;
  lastAttempt: number;
}

class AsyncOperationQueue {
  private queue: AsyncOperation[] = [];
  private isProcessing: boolean = false;
  private retryDelay: number = 5000; // 5 seconds
  private maxRetries: number = 3;

  async enqueue(id: string, operation: () => Promise<void>) {
    logger.info('Enqueueing async operation', { id });
    
    this.queue.push({
      id,
      operation,
      retries: 0,
      maxRetries: this.maxRetries,
      lastAttempt: Date.now()
    });

    if (!this.isProcessing) {
      this.processQueue();
    }
  }

  private async processQueue() {
    if (this.isProcessing || this.queue.length === 0) {
      return;
    }

    this.isProcessing = true;
    logger.info('Processing async operation queue', { queueLength: this.queue.length });

    while (this.queue.length > 0) {
      const operation = this.queue[0];
      const now = Date.now();

      // Skip operations that are waiting for retry
      if (operation.retries > 0 && now - operation.lastAttempt < this.retryDelay) {
        this.isProcessing = false;
        setTimeout(() => this.processQueue(), this.retryDelay);
        return;
      }

      try {
        await operation.operation();
        this.queue.shift(); // Remove successful operation
        logger.info('Async operation completed successfully', { id: operation.id });
      } catch (error) {
        operation.retries++;
        operation.lastAttempt = now;
        
        if (operation.retries >= operation.maxRetries) {
          logger.error('Async operation failed permanently', {
            id: operation.id,
            error,
            retries: operation.retries
          });
          this.queue.shift(); // Remove failed operation
        } else {
          logger.warning('Async operation failed, will retry', {
            id: operation.id,
            error,
            retryAttempt: operation.retries
          });
          // Move to end of queue for retry
          this.queue.push(this.queue.shift()!);
        }
      }
    }

    this.isProcessing = false;
  }
}

export const asyncQueue = new AsyncOperationQueue();