import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useArticleStore } from '../stores/articleStore';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export default function ArticleEditor() {
  const navigate = useNavigate();
  const addArticle = useArticleStore(state => state.addArticle);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('基础');
  const [coverImage, setCoverImage] = useState('');
  const categories = ['基础', '进阶', '技巧'];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const article = {
      id: Date.now().toString(),
      title,
      content,
      category,
      coverImage: coverImage || undefined,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      description: content.slice(0, 100) + '...'
    };

    addArticle(article);
    navigate('/academy');
  };

  return (
    <div className="py-6 px-4">
      <div className="flex items-center justify-between mb-6">
        <button
          onClick={() => navigate('/academy')}
          className="text-red-600 hover:text-red-700 flex items-center"
        >
          <ArrowLeftIcon className="w-5 h-5 mr-1" />
          返回
        </button>
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
        >
          发布
        </button>
      </div>

      <form className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            标题
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
            placeholder="请输入文章标题"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            封面图片URL（可选）
          </label>
          <input
            type="text"
            value={coverImage}
            onChange={(e) => setCoverImage(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
            placeholder="请输入图片URL"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            分类
          </label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
          >
            {categories.map(cat => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            内容
          </label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full h-64 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
            placeholder="请输入文章内容（支持HTML标签）"
          />
        </div>
      </form>
    </div>
  );
}