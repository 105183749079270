import axios from 'axios';
import { logger } from '../utils/clientLogger';
import type { AuthResponse, InvitationCodeResponse } from '../types/auth';

const API_URL = import.meta.env.VITE_API_URL || '/api';

// Create axios instance
const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add response interceptor for logging
apiClient.interceptors.response.use(
  response => response,
  error => {
    logger.error('API request failed', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      error: error.message,
      response: error.response?.data
    });
    throw error;
  }
);

export async function login(username: string, password: string): Promise<AuthResponse> {
  logger.info('Attempting API login', { username });
  try {
    const response = await apiClient.post<AuthResponse>('/auth/login', {
      username,
      password,
    });
    logger.info('API login successful', { username });
    return response.data;
  } catch (error) {
    logger.error('API login failed', error);
    throw error;
  }
}

export async function register(
  username: string, 
  password: string, 
  confirmPassword: string,
  invitationCode: string
): Promise<AuthResponse> {
  logger.info('Attempting API registration', { username });
  try {
    const response = await apiClient.post<AuthResponse>('/auth/register', {
      username,
      password,
      confirmPassword,
      invitationCode
    });
    logger.info('API registration successful', { username });
    return response.data;
  } catch (error) {
    logger.error('API registration failed', error);
    throw error;
  }
}

export async function getInvitationCode(): Promise<InvitationCodeResponse> {
  logger.info('Requesting invitation code');
  try {
    const response = await apiClient.post<InvitationCodeResponse>('/invitation', {});
    logger.info('Invitation code request successful');
    return response.data;
  } catch (error) {
    logger.error('Invitation code request failed', error);
    throw error;
  }
}