import React from 'react';
import clsx from 'clsx';

interface YaoProps {
  isYang: boolean;
  isMoving?: boolean;
  className?: string;
}

const Yao: React.FC<YaoProps> = ({ isYang, isMoving, className }) => (
  <div className={clsx(
    'h-2 my-1',
    className,
    isMoving && 'relative',
    isYang ? 'bg-red-700 rounded' : 'flex justify-between space-x-1'
  )}>
    {!isYang && (
      <>
        <div className="w-5/12 h-full bg-red-700 rounded"></div>
        <div className="w-5/12 h-full bg-red-700 rounded"></div>
      </>
    )}
    {isMoving && (
      <div className="absolute -right-4 w-2 h-2 top-0 bg-red-700 rounded-full" />
    )}
  </div>
);

interface HexagramDisplayProps {
  binary: string;
  movingYao?: number;
  title?: string;
  subtitle?: string;
  onClick?: () => void;
  size?: 'small' | 'normal';
  showThreeLines?: boolean;
}

export default function HexagramDisplay({
  binary,
  movingYao,
  title,
  subtitle,
  onClick,
  size = 'normal',
  showThreeLines = false
}: HexagramDisplayProps) {
  const lines = showThreeLines ? binary.slice(0, 3) : binary;
  const yaoCount = showThreeLines ? 3 : 6;

  return (
    <div 
      className={clsx(
        'flex flex-col items-center p-4',
        onClick && 'cursor-pointer hover:bg-gray-50'
      )}
      onClick={onClick}
    >
      <div className={size === 'small' ? 'w-16' : 'w-20'}>
        {lines.split('').map((bit, index) => (
          <Yao
            key={index}
            isYang={bit === '1'}
            isMoving={movingYao === (yaoCount - 1 - index)}
            className="w-full"
          />
        ))}
      </div>
      {title && (
        <div className={clsx(
          'font-medium text-gray-900 mt-2',
          size === 'small' ? 'text-xs' : 'text-sm'
        )}>{title}</div>
      )}
      {subtitle && (
        <div className={clsx(
          'text-gray-500 mt-1',
          size === 'small' ? 'text-xs' : 'text-sm'
        )}>{subtitle}</div>
      )}
    </div>
  );
}