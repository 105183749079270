import type { UserRole } from '../types/auth';

export const roleDisplayNames: Record<UserRole, string> = {
  'superadmin': '超级管理员',
  'admin': '管理员',
  'user': '普通会员'
};

export function getRoleDisplayName(role: UserRole): string {
  return roleDisplayNames[role] || '普通会员';
}