import axios from 'axios';
import { logger } from '../utils/clientLogger';

const API_URL = import.meta.env.VITE_API_URL || '/api';

export async function resetPassword(username: string, newPassword: string): Promise<boolean> {
  try {
    logger.info('Attempting to reset password', { username });
    await axios.post(`${API_URL}/auth/reset-password`, {
      username,
      newPassword
    });
    logger.info('Password reset successful', { username });
    return true;
  } catch (error) {
    logger.error('Password reset failed', error);
    return false;
  }
}