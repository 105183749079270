export const hexagramInterpretations: Record<string, string> = {
    '111111':'天，健。',
    '000000':'地，顺。',
    '010001':'水馅，危险；雷动，不能停。此卦意思是有危险，但是不能停下来。就像在高速上开车，前面突然出现大雾。',
    '100010':'山止，水陷。此卦有看不见，看不清，看不懂之意。千万不要在危险中停留，停则蒙。',
    '010111':'坎水，是云、雾；需，等待，有祈雨的意思。意思是云，雾成形，要等一会才会下雨。此卦显示事情已成形，即将发生。',
    '111010':'这个卦天在上面，水在下面，天会往上浮，水会往下掉。此卦有分开，分家，不合之意。',
    '000010':'水馅，危险；地，顺。危险藏在柔软的东西里。此卦有像泥石流，水灾一样破坏性之象。',
    '010000':'聚在一起，所有人都柔顺和听话，只有一个人不一样。',
    '110111':'天上的云正在积累，和水天需卦很像。事情发生还需要时间。',
    '111011':'礼，履行；水是履行天的责任。',
    '000111':'这个卦地在上，天在下；地会往下，天会上升，所以是日月轮转。恒长，太平。',
    '111000':'这个卦天在上，地在下；天俞往上，地俞往下；上面不听下面，下面不接上面，天总是天，地总是地。不通。',
    '111101':'晚上，灯火，家人，朋友。',
    '101111':'光明，明亮；天上的火指的是太阳。',
    '000100':'有能力的人把能力藏于内里；外柔内刚，退让和避让。',
    '001000':'雷在地上炸；春雷，万物复苏。进取，开心之象。',
    '011001':'日月相随，季节变化；阳气向下，阴气向上。',
    '100110':'空间密闭不通气，就会生虫子，妖魔鬼怪。',
    '000011':'临，来临；欢迎别人来做客，干实事之象。',
    '110000':'领导视察，走马观花；只看，不干实事。',
    '101001':'光明一下传很远。打官司，经商，发布政令得此卦，结果会很好。',
    '100101':'贲是装饰的意思，买了房子才会休息。有喜事之象。',
    '100000':'阴气很高，阳气剥落。',
    '000001':'复，冬去春来，阴极阳起。灾难中得此卦结果会很好；好事中得此卦要小心坏事发生。',
    '111001':'想多了，不要随便跑。打雷不要乱跑，得此卦要自我反省。得此卦不要再算了，可能是心不诚，也可能是天机不可泄露，再算也算不准，听天由命',
    '100111':'山止，乾健。积累，不败之象。',
    '100001':'养，有节制地运动。',
    '011110':'水淹过树了。大灾。',
    '010010':'坎，水，坎陷。坎水代表雾，空气，冰霜，云。',
    '101101':'丽。',
    '011100':'闭嘴为咸。咸，感于心。',
    '001110':'动会同时产生风，道理是永恒不变的。恒，永远。无常才是恒常。代表不断地变化。',
    '111100':'逃跑。天在动，山不动。',
    '001111':'声名远播。',
    '101000':'慢慢越来越大。',
    '000101':'夷，平。夜晚卦，太阳到了地下。',
    '110101':'团结，帮助。',
    '101011':'火是太阳，泽是湖泊。相互对望，水火不相融。',
    '010100':'艰难，山上有雾，险而不通。此卦象指必须动起来，才能转移变化到雷水解。',
    '001010':'雷动，在水中。解除困难。',
    '100011':'以柔克刚，此卦提醒要小心自己微细欲望和坏习惯。',
    '110001':'雷声越大风越大，风越大雷声越大。相互增益。相互成长的才是朋友。。',
    '011111':'坚决地去掉自己的坏习惯，坏毛病。此卦又有过于刚强之象。',
    '111110':'一个长女嫁给一个老头。以阴驽阳。过于柔弱。不合理的婚姻或婚外情。',
    '011000':'聚集有生命的东西。一群人是萃，一堆物是比。',
    '000110':'种子向上生长，地面的东西向上长，向上发展。',
    '011010':'泽，湖泊等。一个湖泊的表面没有水，水渗透在湖泊下面。即没有水了，器皿有缺，水漏光了。所以表示困。',
    '010110':'水源源不断升上来。',
    '011101':'灭火，把旧的东西去掉，革故鼎新。',
    '101110':'烧火做饭。',
    '001001':'雷，震动。',
    '100100':'山，静止，不动。',
    '110100':'慢慢落下去。明媒正娶。天鹅仙鹤落山上。事情慢慢变好。',
    '001011':'雷，长男。泽，少女。女孩最怕鼓动，男孩最怕甜言蜜语。长男少女，少鼓动，少甜言蜜语。此卦象出嫁，生孩子，婚姻。',
    '001101':'雷，指东边。火，指太阳。东边太阳逐渐升起。',
    '101100':'出差，家人工作很远。太阳从不在一个山头停留。',
    '110110':'风，入。',
    '011011':'泽，说。水聚集的地方，代表实实在在的水。湖泊等。',
    '110010':'风吹雾散，好事变坏，坏事变好。',
    '010011':'节，节约。一节一节的竹子。水放瓶子里。',
    '110011':'大离卦。鸡蛋，孵化出生命。',
    '001100':'大坎卦。夭折之象，凶卦。',
    '010101':'相互成就，相互作用。与地天泰卦原理解读有点像。',
    '101010':'水火不相融，相互不作用。与天地否卦原理解读有点像。',
};