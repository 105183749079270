export type LogLevel = 'info' | 'warning' | 'error' | 'off';

export interface LoggerConfig {
  level: LogLevel;
  enableConsole: boolean;
  enableFileSystem: boolean;
  logDir?: string;
}

export interface ILogger {
  setLevel(level: LogLevel): void;
  info(message: string, data?: any): void;
  warning(message: string, data?: any): void;
  error(message: string, error?: Error | any): void;
}

export const DEFAULT_CONFIG: LoggerConfig = {
  level: 'info',
  enableConsole: true,
  enableFileSystem: true
};