import type { HexagramYaoInterpretations } from '../types/iching';

// 爻辞解释数据
export const yaoInterpretations: Partial<HexagramYaoInterpretations> = {
  // 乾卦爻辞解释示例
  '111111': [
    {
      text: '初九：潜龙勿用。',
      interpretation: '潜龙表示潜藏的龙，比喻具有才能但尚未得到施展的机会。此爻告诫人要耐心等待时机，不要急于表现自己。',
      image: '龙潜于深渊之中',
      application: '处于起步阶段时，应当韬光养晦，积累实力，等待适当的机会。'
    },
    {
      text: '九二：见龙在田，利见大人。',
      interpretation: '龙出现在大地上，表示才能开始得到施展的机会。此爻提示时机已经成熟，可以开始行动。',
      image: '龙在田野中腾跃',
      application: '当具备一定实力和机会时，要把握时机施展才能，寻求贵人帮助。'
    },
    {
      text: '九三：君子终日乾乾，夕惕若，厉无咎。',
      interpretation: '整日保持警惕和奋进的状态，虽有危险但能避免灾祸。',
      image: '君子整日保持警觉',
      application: '处于上升期时要特别谨慎，保持谦虚谨慎的态度，不断完善自己。'
    },
    {
      text: '九四：或跃在渊，无咎。',
      interpretation: '在深渊中跳跃，表示进退两难的处境。此爻提示要根据具体情况灵活应对。',
      image: '龙在深渊中跳跃',
      application: '面对复杂局势时，要审时度势，灵活应变，既不冒进也不过分退缩。'
    },
    {
      text: '九五：飞龙在天，利见大人。',
      interpretation: '龙飞在天空中，表示才能得到充分发挥。此爻是最理想的状态。',
      image: '龙翱翔于天空',
      application: '当位居要职或事业达到顶峰时，要善于团结他人，发挥更大的作用。'
    },
    {
      text: '上九：亢龙有悔。',
      interpretation: '龙飞得太高，表示过于自满，将会有后悔。此爻警示不要过分自大。',
      image: '龙飞得过高',
      application: '处于极盛时期要特别警惕，避免骄傲自满，否则容易招致失败。'
    }
  ],
  
  // 坤卦爻辞解释示例
  '000000': [
    {
      text: '初六：履霜，坚冰至。',
      interpretation: '踩到霜雪，说明坚冰将至。这是一个预兆，提醒人们要有远见。',
      image: '地面上的霜雪',
      application: '做事要有预见性，看到征兆就要提前准备，防患于未然。'
    },
    {
      text: '六二：直、方、大，不习无不利。',
      interpretation: '正直、方正、宽大，不用特意学习就能得到好处。',
      image: '平坦宽广的大地',
      application: '保持正直、宽厚的品格，按照正道行事自然会有好结果。'
    },
    {
      text: '六三：含章可贞。或从王事，无成有终。',
      interpretation: '包含美好的品德，可以正固。或者从事王事，虽然一时没有成就，但最终会有好结果。',
      image: '蕴含美德的君子',
      application: '在工作中要注重积累，即使暂时看不到成果也要坚持到底。'
    },
    {
      text: '六四：括囊，无咎无誉。',
      interpretation: '扎紧口袋，既没有灾祸，也没有称赞。',
      image: '封闭的口袋',
      application: '有时保持低调是明智的选择，不求表现但求稳妥。'
    },
    {
      text: '六五：黄裳，元吉。',
      interpretation: '穿着黄色的衣服，大吉大利。黄色象征中正之色。',
      image: '黄色的礼服',
      application: '保持中庸之道，遵循正道，会得到好结果。'
    },
    {
      text: '上六：龙战于野，其血玄黄。',
      interpretation: '龙在旷野争斗，血呈玄黄色。表示争斗到极点的状态。',
      image: '龙在旷野搏斗',
      application: '过分争斗必有损伤，要懂得适可而止。'
    }
  ]
};