import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Lunar } from 'lunar-typescript';
import type { Trigram, YaoPosition, HexagramResult } from '../types/iching';
import { 
  calculateMainHexagram, 
  calculateChangedHexagram, 
  calculateInterlinkedHexagrams 
} from '../utils/hexagramCalculator';
import { getHexagramData } from '../data/hexagrams';
import { logger } from '../utils/clientLogger';
import * as divinationApi from '../api/divination';
import { asyncQueue } from '../utils/asyncOperations';

interface DivinationState {
  currentResult: HexagramResult | null;
  savedResults: HexagramResult[];
  upperTrigram: Trigram | '';
  lowerTrigram: Trigram | '';
  movingYao: YaoPosition | '';
  setSelections: (upper: Trigram | '', lower: Trigram | '', yao: YaoPosition | '') => void;
  performManualDivination: (upperTrigram: Trigram, lowerTrigram: Trigram, movingYao: YaoPosition) => void;
  performTimeDivination: () => void;
  saveCurrentResult: () => Promise<boolean>;
  loadSavedResult: (result: HexagramResult) => void;
  deleteSavedResult: (timestamp: string) => Promise<void>;
  syncWithServer: () => Promise<void>;
  reset: () => void;
}

export const useDivinationStore = create<DivinationState>()(
  persist(
    (set, get) => ({
      currentResult: null,
      savedResults: [],
      upperTrigram: '',
      lowerTrigram: '',
      movingYao: '',

      setSelections: (upper, lower, yao) => {
        logger.info('Setting divination selections', { upper, lower, yao });
        set({ upperTrigram: upper, lowerTrigram: lower, movingYao: yao });
      },

      performManualDivination: (upperTrigram, lowerTrigram, movingYao) => {
        logger.info('Performing manual divination', { upperTrigram, lowerTrigram, movingYao });
        
        const mainHexagram = calculateMainHexagram(upperTrigram, lowerTrigram);
        const changedHexagram = calculateChangedHexagram(mainHexagram, movingYao);
        const { useHexagram, bodyHexagram } = calculateInterlinkedHexagrams(mainHexagram, movingYao);
        
        const hexagramData = getHexagramData(mainHexagram);
        
        const result: HexagramResult = {
          mainHexagram,
          changedHexagram,
          useHexagram,
          bodyHexagram,
          mainText: hexagramData.text,
          yaoTexts: hexagramData.yaoTexts,
          movingYao: ['初爻', '二爻', '三爻', '四爻', '五爻', '上爻'].indexOf(movingYao),
          timestamp: new Date().toISOString(),
          upperTrigram,
          lowerTrigram,
          movingYaoPosition: movingYao
        };

        logger.info('Manual divination completed', { result });
        set({ currentResult: result });
      },

      performTimeDivination: () => {
        logger.info('Performing time-based divination');
        
        const now = new Date();
        const lunar = Lunar.fromDate(now);
        
        const yearValue = lunar.getYear() % 8;
        const monthValue = lunar.getMonth() % 8;
        const dayValue = lunar.getDay() % 8;
        const hourValue = Math.floor((now.getHours() + 1) / 2) % 8;

        logger.info('Calculated time values', { yearValue, monthValue, dayValue, hourValue });

        const trigrams: Trigram[] = ['乾', '兑', '离', '震', '巽', '坎', '艮', '坤'];

        const upperTrigram = trigrams[(yearValue + monthValue + dayValue) % 8];
        const lowerTrigram = trigrams[(yearValue + monthValue + dayValue + hourValue) % 8];
        
        const movingYaoIndex = (yearValue + monthValue + dayValue + hourValue) % 6;
        const movingYao = ['初爻', '二爻', '三爻', '四爻', '五爻', '上爻'][movingYaoIndex] as YaoPosition;

        logger.info('Time divination parameters calculated', { upperTrigram, lowerTrigram, movingYao });
        
        set({ upperTrigram, lowerTrigram, movingYao });
        get().performManualDivination(upperTrigram, lowerTrigram, movingYao);
      },

      saveCurrentResult: async () => {
        const { currentResult, savedResults } = get();
        logger.info('Attempting to save divination result', { currentResult });
        
        if (currentResult) {
          const exists = savedResults.some(
            result => result.timestamp === currentResult.timestamp
          );
          
          if (!exists) {
            logger.info('Saving new divination result');
            set({ savedResults: [...savedResults, currentResult] });
            
            // Asynchronously save to server
            asyncQueue.enqueue(
              `save-${currentResult.timestamp}`,
              async () => await divinationApi.saveResult(currentResult)
            );
            
            return true;
          } else {
            logger.warning('Divination result already exists', { timestamp: currentResult.timestamp });
          }
        } else {
          logger.warning('No current result to save');
        }
        return false;
      },

      loadSavedResult: (result: HexagramResult) => {
        logger.info('Loading saved divination result', { timestamp: result.timestamp });
        set({ 
          currentResult: result,
          upperTrigram: result.upperTrigram,
          lowerTrigram: result.lowerTrigram,
          movingYao: result.movingYaoPosition
        });
      },

      deleteSavedResult: async (timestamp: string) => {
        logger.info('Deleting saved divination result', { timestamp });
        const { savedResults } = get();
        
        set({
          savedResults: savedResults.filter(result => result.timestamp !== timestamp)
        });

        // Asynchronously delete from server
        asyncQueue.enqueue(
          `delete-${timestamp}`,
          async () => await divinationApi.deleteResult(timestamp)
        );
      },

      syncWithServer: async () => {
        logger.info('Syncing divination results with server');
        try {
          const serverResults = await divinationApi.getResults();
          set({ savedResults: serverResults });
          logger.info('Divination results synced successfully', { count: serverResults.length });
        } catch (error) {
          logger.error('Failed to sync divination results', error);
        }
      },

      reset: () => {
        logger.info('Resetting divination store');
        set({
          currentResult: null,
          savedResults: [],
          upperTrigram: '',
          lowerTrigram: '',
          movingYao: ''
        });
      }
    }),
    {
      name: 'divination-storage',
      partialize: (state) => ({ 
        savedResults: state.savedResults 
      }),
    }
  )
);