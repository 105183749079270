import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Layout from './components/Layout';
import Divination from './pages/Divination';
import DivinationResult from './pages/DivinationResult';
import IChingLibrary from './pages/IChingLibrary';
import Academy from './pages/Academy';
import ArticleDetail from './pages/ArticleDetail';
import ArticleEditor from './pages/ArticleEditor';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Invitation from './pages/Invitation';
import HexagramDetail from './pages/HexagramDetail';
import HexagramTextDetail from './pages/HexagramTextDetail';
import EarlyHeavenTrigramDetail from './pages/EarlyHeavenTrigramDetail';
import { useAuthStore } from './stores/authStore';
import { ENABLE_INVITATION_PAGE } from './config/features';

function SessionManager() {
  const { isAuthenticated, sessionExpiry, updateSession, logout } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && sessionExpiry) {
      if (Date.now() > sessionExpiry) {
        logout();
        navigate('/');
        return;
      }

      const handleActivity = () => {
        updateSession();
      };

      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('keydown', handleActivity);
      window.addEventListener('click', handleActivity);
      window.addEventListener('scroll', handleActivity);

      return () => {
        window.removeEventListener('mousemove', handleActivity);
        window.removeEventListener('keydown', handleActivity);
        window.removeEventListener('click', handleActivity);
        window.removeEventListener('scroll', handleActivity);
      };
    }
  }, [isAuthenticated, sessionExpiry, updateSession, logout, navigate]);

  return null;
}

function App() {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);

  return (
    <Router>
      <SessionManager />
      <Routes>
        {ENABLE_INVITATION_PAGE && <Route path="/invitation" element={<Invitation />} />}
        {isAuthenticated ? (
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="/divination" replace />} />
            <Route path="divination" element={<Divination />} />
            <Route path="divination/result" element={<DivinationResult />} />
            <Route path="library" element={<IChingLibrary />} />
            <Route path="academy" element={<Academy />} />
            <Route path="academy/article/:id" element={<ArticleDetail />} />
            <Route path="academy/new" element={<ArticleEditor />} />
            <Route path="profile" element={<Profile />} />
            <Route path="hexagram/:binary" element={<HexagramDetail />} />
            <Route path="hexagram/:binary/text" element={<HexagramTextDetail />} />
            <Route path="hexagram/:binary/text/:index" element={<HexagramTextDetail />} />
            <Route path="trigram/:binary" element={<EarlyHeavenTrigramDetail />} />
          </Route>
        ) : (
          <Route path="*" element={<Login />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;