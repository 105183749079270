import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Lunar } from 'lunar-typescript';

const EARTHLY_BRANCHES = ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥'];

// 六十甲子对应表
const GANZHI_CYCLE: Record<string, number> = {
  '甲子':1, '乙丑':2, '丙寅':3, '丁卯':4, '戊辰':5, '己巳':6, '庚午':7, '辛未':8, '壬申':9, '癸酉':10,
  '甲戌':11, '乙亥':12, '丙子':13, '丁丑':14, '戊寅':15, '己卯':16, '庚辰':17, '辛巳':18, '壬午':19, '癸未':20,
  '甲申':21, '乙酉':22, '丙戌':23, '丁亥':24, '戊子':25, '己丑':26, '庚寅':27, '辛卯':28, '壬辰':29, '癸巳':30,
  '甲午':31, '乙未':32, '丙申':33, '丁酉':34, '戊戌':35, '己亥':36, '庚子':37, '辛丑':38, '壬寅':39, '癸卯':40,
  '甲辰':41, '乙巳':42, '丙午':43, '丁未':44, '戊申':45, '己酉':46, '庚戌':47, '辛亥':48, '壬子':49, '癸丑':50,
  '甲寅':51, '乙卯':52, '丙辰':53, '丁巳':54, '戊午':55, '己未':56, '庚申':57, '辛酉':58, '壬戌':59, '癸亥':60
};

// 地支对应数字
const EARTHLY_BRANCHES_MAP:Record<string, number> = {
  '子':1, '丑':2, '寅':3, '卯':4, '辰':5, '巳':6,
  '午':7, '未':8, '申':9, '酉':10, '戌':11, '亥':12
};

function getChineseHour(hour: number): string {
  const index = Math.floor((hour + 1) / 2) % 12;
  return EARTHLY_BRANCHES[index];
}

export default function LunarDateTime() {
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [lunar, setLunar] = useState(Lunar.fromDate(new Date()));

  useEffect(() => {
    const timer = setInterval(() => {
      const now = dayjs();
      setCurrentTime(now);
      setLunar(Lunar.fromDate(now.toDate()));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const currentHour = getChineseHour(currentTime.hour());
  const yearGanZhi = lunar.getYearInGanZhi();
  const monthGanZhi = lunar.getMonthInGanZhi();
  const dayGanZhi = lunar.getDayInGanZhi();

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="text-center space-y-4">
        <div className="text-gray-600">
          {yearGanZhi}年
          {monthGanZhi}月
          {dayGanZhi}日
        </div>

        <div className="text-2xl font-bold text-red-600">
          {currentHour}时
        </div>

        <div className="text-sm text-gray-500">
          {yearGanZhi}：{GANZHI_CYCLE[yearGanZhi]} {monthGanZhi}：{GANZHI_CYCLE[monthGanZhi]} {dayGanZhi}：{GANZHI_CYCLE[dayGanZhi]} {currentHour}：{EARTHLY_BRANCHES_MAP[currentHour]}
        </div>

        <div className="text-sm text-gray-500">
          {currentTime.format('YYYY年MM月DD日 HH:mm:ss')}
        </div>
      </div>
    </div>
  );
}