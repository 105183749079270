import { useState } from 'react';
import AuthForm from '../components/AuthForm';

export default function Login() {
  const [mode, setMode] = useState<'login' | 'register'>('login');

  const toggleMode = () => {
    setMode(mode === 'login' ? 'register' : 'login');
  };

  return (
    <div className="min-h-screen flex flex-col bg-stone-50">
      <div className="w-full bg-white border-b border-gray-100">
        <div className="max-w-lg mx-auto px-4 py-6">
          <div className="flex flex-col items-center">
            <img 
              src="/logo.png" 
              alt="梅花易道书院" 
              className="w-full max-w-[529px] aspect-[529/367] object-contain mb-6"
            />
          </div>
        </div>
      </div>

      <div className="flex-1 flex items-center justify-center px-4 pt-safe-top pb-12">
        <AuthForm mode={mode} onToggleMode={toggleMode} />
      </div>
    </div>
  );
}