import axios from 'axios';
import { logger } from '../utils/clientLogger';
import type { HexagramResult } from '../types/iching';

const API_URL = import.meta.env.VITE_API_URL || '/api';

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const setAuthToken = (token: string | null) => {
  if (token) {
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete apiClient.defaults.headers.common['Authorization'];
  }
};

// Add response interceptor for logging
apiClient.interceptors.response.use(
  response => response,
  error => {
    logger.error('API request failed', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      error: error.message,
      response: error.response?.data
    });
    throw error;
  }
);

export async function saveResult(result: HexagramResult): Promise<void> {
  logger.info('Saving divination result to server', { timestamp: result.timestamp });
  try {
    await apiClient.post('/divination/results', result);
    logger.info('Divination result saved successfully');
  } catch (error) {
    logger.error('Failed to save divination result', error);
    throw error;
  }
}

export async function getResults(): Promise<HexagramResult[]> {
  logger.info('Fetching divination results from server');
  try {
    const response = await apiClient.get('/divination/results');
    logger.info('Divination results fetched successfully', { count: response.data.length });
    return response.data;
  } catch (error) {
    logger.error('Failed to fetch divination results', error);
    throw error;
  }
}

export async function deleteResult(timestamp: string): Promise<void> {
  logger.info('Deleting divination result from server', { timestamp });
  try {
    await apiClient.delete(`/divination/results/${timestamp}`);
    logger.info('Divination result deleted successfully');
  } catch (error) {
    logger.error('Failed to delete divination result', error);
    throw error;
  }
}