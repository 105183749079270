import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import HexagramDisplay from '../components/HexagramDisplay';
import { hexagramNames } from '../data/hexagramNames';
import { getHexagramData } from '../data/hexagrams';
import { hexagramInterpretations } from '../data/hexagramInterpretations';

const HexagramDetail: FC = () => {
  const navigate = useNavigate();
  const { binary } = useParams();
  
  if (!binary || !hexagramNames[binary]) {
    return <div>卦象不存在</div>;
  }

  const hexagramName = hexagramNames[binary];
  const hexagramData = getHexagramData(binary);
  const interpretation = hexagramInterpretations[binary] || '暂无解释';

  const handleYaoTextClick = (index: number) => {
    navigate(`/hexagram/${binary}/text/${index}`);
  };

  return (
    <div className="max-w-lg mx-auto py-6 px-4">
      <button
        onClick={() => navigate(-1)}
        className="mb-6 text-red-600 hover:text-red-700 flex items-center"
      >
        <ArrowLeftIcon className="w-5 h-5 mr-1" />
        返回
      </button>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-center mb-8">
          <div className="text-center">
            <HexagramDisplay
              binary={binary}
            />
            <h1 className="text-2xl font-bold text-gray-900 mt-4">{hexagramName}</h1>
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">卦辞</h2>
            <p className="text-gray-600 p-4 rounded-lg border border-gray-200">
              {hexagramData.text}
            </p>
          </div>

          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">爻辞</h2>
            <div className="space-y-2">
              {hexagramData.yaoTexts.map((text, index) => (
                <div 
                  key={index} 
                  className="bg-white p-4 rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer"
                  onClick={() => handleYaoTextClick(index)}
                >
                  <p className="text-gray-600">{text}</p>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h2 className="text-lg font-bold text-gray-900 mb-2">书院解读</h2>
            <div className="bg-white p-4 rounded-lg border border-gray-200">
              <p className="text-gray-600">{interpretation}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HexagramDetail;