import { useNavigate } from 'react-router-dom';
import DivinationResultDisplay from '../components/DivinationResultDisplay';
import { useDivinationStore } from '../stores/divinationStore';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export default function DivinationResult() {
  const navigate = useNavigate();
  const { currentResult, saveCurrentResult } = useDivinationStore();

  if (!currentResult) {
    navigate('/divination');
    return null;
  }

  const handleSave = async () => {
    const success = await saveCurrentResult();
    if (success) {
      alert('卦例保存成功');
    } else {
      alert('卦例已存在');
    }
  };

  return (
    <div className="py-6">
      <div className="flex items-center mb-6">
        <button
          onClick={() => navigate('/divination')}
          className="text-red-600 hover:text-red-700 flex items-center"
        >
          <ArrowLeftIcon className="w-5 h-5 mr-1" />
          返回
        </button>
      </div>

      <DivinationResultDisplay result={currentResult} />
      
      <button
        onClick={handleSave}
        className="w-full mt-6 py-3 px-4 border border-red-600 rounded-lg shadow-sm text-sm font-medium text-red-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        保存卦例
      </button>
    </div>
  );
}