import { Link, useLocation } from 'react-router-dom';
import { BookOpenIcon, UserIcon, AcademicCapIcon, SparklesIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

const navigation = [
  { name: '起卦', path: '/divination', icon: SparklesIcon },
  { name: '易经', path: '/library', icon: BookOpenIcon },
  { name: '书院', path: '/academy', icon: AcademicCapIcon },
  { name: '我的', path: '/profile', icon: UserIcon },
];

export default function Navigation() {
  const location = useLocation();

  return (
    <nav className="fixed bottom-0 left-0 right-0 z-50 bg-white border-t border-gray-200">
      <div className="max-w-lg mx-auto">
        <div className="flex justify-around items-center h-[50px]">
          {navigation.map(({ name, path, icon: Icon }) => {
            const isActive = location.pathname === path;
            return (
              <Link
                key={path}
                to={path}
                className={clsx(
                  'flex-1 flex flex-col items-center justify-center h-full',
                  'active:opacity-70',
                  isActive ? 'text-red-600' : 'text-gray-400'
                )}
              >
                <Icon className="w-5 h-5" />
                <span className="mt-0.5 text-[10px]">{name}</span>
              </Link>
            );
          })}
        </div>
        <div className="h-[env(safe-area-inset-bottom)] bg-white" />
      </div>
    </nav>
  );
}