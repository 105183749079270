import { useState, useEffect } from 'react';
import { useAuthStore } from '../stores/authStore';
import clsx from 'clsx';

interface AuthFormProps {
  mode: 'login' | 'register';
  onToggleMode: () => void;
}

interface Notification {
  type: 'success' | 'error';
  message: string;
}

export default function AuthForm({ mode, onToggleMode }: AuthFormProps) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [invitationCode, setInvitationCode] = useState('');
  const [notification, setNotification] = useState<Notification | null>(null);
  const { login, register } = useAuthStore();

  useEffect(() => {
    let timer: number;
    if (notification) {
      timer = window.setTimeout(() => {
        setNotification(null);
      }, 2000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [notification]);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s/g, '');
    setUsername(value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s/g, '');
    setPassword(value);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s/g, '');
    setConfirmPassword(value);
  };

  const handleInvitationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s/g, '');
    setInvitationCode(value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setNotification(null);

    if (!username || !password) {
      setNotification({
        type: 'error',
        message: '请输入用户名和密码'
      });
      return;
    }

    if (mode === 'register') {
      if (!confirmPassword) {
        setNotification({
          type: 'error',
          message: '请确认密码'
        });
        return;
      }

      if (password !== confirmPassword) {
        setNotification({
          type: 'error',
          message: '两次输入的密码不一致'
        });
        return;
      }

      if (!invitationCode) {
        setNotification({
          type: 'error',
          message: '请输入邀请码'
        });
        return;
      }
    }

    try {
      if (mode === 'login') {
        const success = await login(username, password);
        if (!success) {
          setNotification({
            type: 'error',
            message: '用户名或密码错误'
          });
        }
      } else {
        const success = await register(username, password, confirmPassword, invitationCode);
        if (success) {
          setNotification({
            type: 'success',
            message: '注册成功'
          });
        } else {
          setNotification({
            type: 'error',
            message: '注册失败，请检查邀请码是否有效'
          });
        }
      }
    } catch (err) {
      setNotification({
        type: 'error',
        message: '操作失败，请稍后重试'
      });
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="space-y-4">
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
              用户名
            </label>
            <input
              id="username"
              type="text"
              required
              className="mt-1 block w-full px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-red-500 focus:border-red-500"
              placeholder="请输入方便书院识别的用户名"
              value={username}
              onChange={handleUsernameChange}
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              密码
            </label>
            <input
              id="password"
              type="password"
              required
              className="mt-1 block w-full px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-red-500 focus:border-red-500"
              placeholder="请输入易记密码，暂不支持找回与修改"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>

          {mode === 'register' && (
            <>
              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                  确认密码
                </label>
                <input
                  id="confirmPassword"
                  type="password"
                  required
                  className="mt-1 block w-full px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="请再次输入密码"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
              </div>

              <div>
                <label htmlFor="invitationCode" className="block text-sm font-medium text-gray-700">
                  邀请码
                </label>
                <input
                  id="invitationCode"
                  type="text"
                  required
                  className="mt-1 block w-full px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="请输入邀请码"
                  value={invitationCode}
                  onChange={handleInvitationCodeChange}
                />
              </div>
            </>
          )}
        </div>

        {notification && (
          <div
            className={clsx(
              'text-sm text-center py-2 px-4 rounded-lg transition-opacity duration-200',
              notification.type === 'success' 
                ? 'bg-green-50 text-green-600'
                : 'bg-red-50 text-red-600'
            )}
          >
            {notification.message}
          </div>
        )}

        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors"
        >
          {mode === 'login' ? '登录' : '注册'}
        </button>

        <button
          type="button"
          onClick={onToggleMode}
          className="w-full text-sm text-red-600 hover:text-red-700"
        >
          {mode === 'login' ? '没有账号？立即注册' : '已有账号？立即登录'}
        </button>

        <div className="text-center text-xs text-gray-500 mt-8 mb-12">
          <p>Copyright©2024-2034 梅花易道书院 版权所有</p>
        </div>
      </form>
    </div>
  );
}