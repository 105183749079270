import { Outlet } from 'react-router-dom';
import Navigation from './Navigation';

export default function Layout() {
  return (
    <div className="min-h-screen flex flex-col bg-stone-50">
      <main className="flex-1 w-full">
        <div className="max-w-lg mx-auto px-4 pb-[60px]">
          <Outlet />
        </div>
      </main>
      <Navigation />
    </div>
  );
}