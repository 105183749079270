import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useArticleStore } from '../stores/articleStore';
import { useAuthStore } from '../stores/authStore';
import { PlusIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

export default function Academy() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState<string>('全部');
  const articles = useArticleStore(state => state.articles);
  const user = useAuthStore(state => state.user);
  const categories = ['全部', '基础', '进阶', '技巧'];
  
  const filteredArticles = selectedCategory === '全部'
    ? articles
    : articles.filter(article => article.category === selectedCategory);

  const handleArticleClick = (id: string) => {
    navigate(`/academy/article/${id}`);
  };

  const handleNewArticle = () => {
    navigate('/academy/new');
  };

  return (
    <div className="py-6">
      <div className="mb-8 flex space-x-3 overflow-x-auto pb-2">
        {categories.map(category => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={clsx(
              'px-6 py-2.5 rounded-full text-sm font-medium whitespace-nowrap transition-colors',
              selectedCategory === category
                ? 'bg-red-600 text-white shadow-sm'
                : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-200'
            )}
          >
            {category}
          </button>
        ))}
      </div>

      {user?.role === 'superadmin' && (
        <button
          onClick={handleNewArticle}
          className="mb-6 w-full flex items-center justify-center px-4 py-3 border border-red-600 rounded-lg text-red-600 hover:bg-red-50 transition-colors"
        >
          <PlusIcon className="w-5 h-5 mr-2" />
          <span>新增文章</span>
        </button>
      )}

      <div className="space-y-4">
        {filteredArticles.map(article => (
          <div
            key={article.id}
            onClick={() => handleArticleClick(article.id)}
            className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow cursor-pointer"
          >
            {article.coverImage && (
              <img
                src={article.coverImage}
                alt={article.title}
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
            )}
            <h3 className="text-lg font-semibold text-gray-900">{article.title}</h3>
            <p className="mt-2 text-gray-600">{article.description}</p>
            <span className="mt-3 inline-block px-3 py-1 text-sm bg-red-50 text-red-600 rounded-full font-medium">
              {article.category}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}