import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { hexagramNames } from '../data/hexagramNames';
import { getHexagramData } from '../data/hexagrams';
import { yaoInterpretations } from '../data/yaoInterpretations';

const HexagramTextDetail: FC = () => {
  const navigate = useNavigate();
  const { binary, index } = useParams<{ binary: string; index?: string }>();

  if (!binary || !hexagramNames[binary] || !index) {
    return <div>内容不存在</div>;
  }

  const hexagramName = hexagramNames[binary];
  const hexagramData = getHexagramData(binary);
  const yaoIndex = Number(index);
  const yaoText = hexagramData.yaoTexts[yaoIndex];
  const yaoInterpretation = yaoInterpretations[binary]?.[yaoIndex];

  return (
    <div className="max-w-lg mx-auto py-6 px-4">
      <button
        onClick={() => navigate(-1)}
        className="mb-6 text-red-600 hover:text-red-700 flex items-center"
      >
        <ArrowLeftIcon className="w-5 h-5 mr-1" />
        返回
      </button>

      <div className="bg-white rounded-lg shadow p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-4">
          {hexagramName}
        </h1>

        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-bold text-gray-900 mb-2">原文</h3>
            <p className="text-gray-600">{yaoText}</p>
          </div>

          {yaoInterpretation ? (
            <>
              <div>
                <h3 className="text-lg font-bold text-gray-900 mb-2">解释</h3>
                <p className="text-gray-600 leading-relaxed">
                  {yaoInterpretation.interpretation}
                </p>
              </div>

              <div>
                <h3 className="text-lg font-bold text-gray-900 mb-2">象征</h3>
                <p className="text-gray-600">{yaoInterpretation.image}</p>
              </div>

              <div>
                <h3 className="text-lg font-bold text-gray-900 mb-2">应用</h3>
                <p className="text-gray-600">{yaoInterpretation.application}</p>
              </div>
            </>
          ) : (
            <div className="text-gray-500 italic">详细解释正在编撰中...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HexagramTextDetail;
