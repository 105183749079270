import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useArticleStore } from '../stores/articleStore';

export default function ArticleDetail() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const article = useArticleStore(state => state.articles.find(a => a.id === id));

  if (!article) {
    return (
      <div className="py-6 px-4">
        <div className="flex items-center mb-6">
          <button
            onClick={() => navigate('/academy')}
            className="text-red-600 hover:text-red-700 flex items-center"
          >
            <ArrowLeftIcon className="w-5 h-5 mr-1" />
            返回
          </button>
        </div>
        <div className="text-center text-gray-500">文章不存在</div>
      </div>
    );
  }

  return (
    <div className="py-6 px-4">
      <div className="flex items-center mb-6">
        <button
          onClick={() => navigate('/academy')}
          className="text-red-600 hover:text-red-700 flex items-center"
        >
          <ArrowLeftIcon className="w-5 h-5 mr-1" />
          返回
        </button>
      </div>

      <article className="prose prose-stone max-w-none">
        <h1 className="text-2xl font-bold text-gray-900 mb-4">{article.title}</h1>
        
        {article.coverImage && (
          <img
            src={article.coverImage}
            alt={article.title}
            className="w-full rounded-lg mb-6 object-cover"
          />
        )}
        
        <div 
          className="text-gray-600 leading-relaxed"
          dangerouslySetInnerHTML={{ __html: article.content }}
        />
      </article>
    </div>
  );
}