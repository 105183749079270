import { useState } from 'react';
import { useAuthStore } from '../stores/authStore';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { resetPassword } from '../services/userService';

interface SettingsProps {
  onClose: () => void;
}

export default function Settings({ onClose }: SettingsProps) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const user = useAuthStore(state => state.user);

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (!newPassword || !confirmPassword) {
      setError('请填写所有密码字段');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('两次输入的密码不一致');
      return;
    }

    if (newPassword.length < 6) {
      setError('密码长度至少为6位');
      return;
    }

    setIsLoading(true);
    try {
      if (!user?.username) {
        throw new Error('User not found');
      }
      
      const success = await resetPassword(user.username, newPassword);
      if (success) {
        alert('密码修改成功');
        onClose();
      } else {
        setError('修改密码失败，请重试');
      }
    } catch (err) {
      setError('修改密码失败，请重试');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-white z-50">
      <div className="max-w-lg mx-auto px-4 py-6">
        <button
          onClick={onClose}
          className="mb-6 text-red-600 hover:text-red-700 flex items-center"
        >
          <ArrowLeftIcon className="w-5 h-5 mr-1" />
          返回
        </button>

        <div className="bg-white rounded-lg">
          <h2 className="text-xl font-bold text-gray-900 mb-6">设置</h2>

          <form onSubmit={handlePasswordReset} className="space-y-6">
            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                新密码
              </label>
              <input
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="mt-1 block w-full px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-red-500 focus:border-red-500"
                placeholder="请输入新密码"
              />
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                确认新密码
              </label>
              <input
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="mt-1 block w-full px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-red-500 focus:border-red-500"
                placeholder="请再次输入新密码"
              />
            </div>

            {error && (
              <div className="text-red-600 text-sm">{error}</div>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-gray-400"
            >
              {isLoading ? '修改中...' : '确认修改'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}