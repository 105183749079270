// 六十四卦名称映射（按传统顺序排列）
export const hexagramNames: Record<string, string> = {
  '111111': '乾为天',
  '000000': '坤为地',
  '010001': '水雷屯',
  '100010': '山水蒙',
  '010111': '水天需',
  '111010': '天水讼',
  '000010': '地水师',
  '010000': '水地比',
  '110111': '风天小畜',
  '111011': '天泽履',
  '000111': '地天泰',
  '111000': '天地否',
  '111101': '天火同人',
  '101111': '火天大有',
  '000100': '地山谦',
  '001000': '雷地豫',
  '011001': '泽雷随',
  '100110': '山风蛊',
  '000011': '地泽临',
  '110000': '风地观',
  '101001': '火雷噬嗑',
  '100101': '山火贲',
  '100000': '山地剥',
  '000001': '地雷复',
  '111001': '天雷无妄',
  '100111': '山天大畜',
  '100001': '山雷颐',
  '011110': '泽风大过',
  '010010': '坎为水',
  '101101': '离为火',
  '011100': '泽山咸',
  '001110': '雷风恒',
  '111100': '天山遁',
  '001111': '雷天大壮',
  '101000': '火地晋',
  '000101': '地火明夷',
  '110101': '风火家人',
  '101011': '火泽睽',
  '010100': '水山蹇',
  '001010': '雷水解',
  '100011': '山泽损',
  '110001': '风雷益',
  '011111': '泽天夬',
  '111110': '天风姤',
  '011000': '泽地萃',
  '000110': '地风升',
  '011010': '泽水困',
  '010110': '水风井',
  '011101': '泽火革',
  '101110': '火风鼎',
  '001001': '震为雷',
  '100100': '艮为山',
  '110100': '风山渐',
  '001011': '雷泽归妹',
  '001101': '雷火丰',
  '101100': '火山旅',
  '110110': '巽为风',
  '011011': '兑为泽',
  '110010': '风水涣',
  '010011': '水泽节',
  '110011': '风泽中孚',
  '001100': '雷山小过',
  '010101': '水火既济',
  '101010': '火水未济'
};

// 简化卦名（只显示卦名，不显示卦象）
export const simplifiedHexagramNames: Record<string, string> = {
  '111111': '乾',
  '000000': '坤',
  '010001': '屯',
  '100010': '蒙',
  '010111': '需',
  '111010': '讼',
  '000010': '师',
  '010000': '比',
  '110111': '小畜',
  '111011': '履',
  '000111': '泰',
  '111000': '否',
  '111101': '同人',
  '101111': '大有',
  '000100': '谦',
  '001000': '豫',
  '011001': '随',
  '100110': '蛊',
  '000011': '临',
  '110000': '观',
  '101001': '噬嗑',
  '100101': '贲',
  '100000': '剥',
  '000001': '复',
  '111001': '无妄',
  '100111': '大畜',
  '100001': '颐',
  '011110': '大过',
  '010010': '坎',
  '101101': '离',
  '011100': '咸',
  '001110': '恒',
  '111100': '遁',
  '001111': '大壮',
  '101000': '晋',
  '000101': '明夷',
  '110101': '家人',
  '101011': '睽',
  '010100': '蹇',
  '001010': '解',
  '100011': '损',
  '110001': '益',
  '011111': '夬',
  '111110': '姤',
  '011000': '萃',
  '000110': '升',
  '011010': '困',
  '010110': '井',
  '011101': '革',
  '101110': '鼎',
  '001001': '震',
  '100100': '艮',
  '110100': '渐',
  '001011': '归妹',
  '001101': '丰',
  '101100': '旅',
  '110110': '巽',
  '011011': '兑',
  '110010': '涣',
  '010011': '节',
  '110011': '中孚',
  '001100': '小过',
  '010101': '既济',
  '101010': '未济'
};