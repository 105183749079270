import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LunarDateTime from '../components/LunarDateTime';
import { useDivinationStore } from '../stores/divinationStore';
import type { Trigram, YaoPosition } from '../types/iching';
import clsx from 'clsx';

const trigrams: Trigram[] = ['乾', '兑', '离', '震', '巽', '坎', '艮', '坤'];
const yaoPositions: YaoPosition[] = ['初爻', '二爻', '三爻', '四爻', '五爻', '上爻'];

const chunkArray = <T,>(arr: T[], size: number): T[][] => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
    arr.slice(i * size, i * size + size)
  );
};

export default function Divination() {
  const navigate = useNavigate();
  const [showLunarTime, setShowLunarTime] = useState(false);
  const { 
    upperTrigram,
    lowerTrigram,
    movingYao,
    setSelections,
    performManualDivination,
    performTimeDivination
  } = useDivinationStore();

  const handleUpperTrigramChange = (value: Trigram) => {
    setSelections(value, lowerTrigram, movingYao);
  };

  const handleLowerTrigramChange = (value: Trigram) => {
    setSelections(upperTrigram, value, movingYao);
  };

  const handleMovingYaoChange = (value: YaoPosition) => {
    setSelections(upperTrigram, lowerTrigram, value);
  };

  const handleManualDivination = () => {
    if (!upperTrigram || !lowerTrigram || !movingYao) {
      return;
    }
    performManualDivination(upperTrigram, lowerTrigram, movingYao);
    navigate('/divination/result');
  };

  const handleTimeDivination = () => {
    performTimeDivination();
    navigate('/divination/result');
  };

  const trigramRows = chunkArray(trigrams, 4);
  const yaoRows = chunkArray(yaoPositions, 3);

  return (
    <div className="max-w-lg mx-auto py-6">
      <button
        onClick={() => setShowLunarTime(!showLunarTime)}
        className="w-full mb-4 py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        {showLunarTime ? '隐藏时辰' : '显示时辰'}
      </button>

      {showLunarTime && (
        <div className="mb-4">
          <LunarDateTime />
        </div>
      )}

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">指定起卦</h2>
        
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">
              上卦
            </label>
            <div className="space-y-3">
              {trigramRows.map((row, rowIndex) => (
                <div key={`upper-${rowIndex}`} className="grid grid-cols-4 gap-3">
                  {row.map((trigram) => (
                    <label
                      key={trigram}
                      className={clsx(
                        'flex items-center justify-center p-3 rounded-lg cursor-pointer transition-colors',
                        upperTrigram === trigram
                          ? 'bg-red-600 text-white'
                          : 'bg-white text-gray-600 border border-gray-200 hover:bg-gray-50'
                      )}
                    >
                      <input
                        type="radio"
                        name="upperTrigram"
                        value={trigram}
                        checked={upperTrigram === trigram}
                        onChange={(e) => handleUpperTrigramChange(e.target.value as Trigram)}
                        className="hidden"
                      />
                      <span>{trigram}</span>
                    </label>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">
              下卦
            </label>
            <div className="space-y-3">
              {trigramRows.map((row, rowIndex) => (
                <div key={`lower-${rowIndex}`} className="grid grid-cols-4 gap-3">
                  {row.map((trigram) => (
                    <label
                      key={trigram}
                      className={clsx(
                        'flex items-center justify-center p-3 rounded-lg cursor-pointer transition-colors',
                        lowerTrigram === trigram
                          ? 'bg-red-600 text-white'
                          : 'bg-white text-gray-600 border border-gray-200 hover:bg-gray-50'
                      )}
                    >
                      <input
                        type="radio"
                        name="lowerTrigram"
                        value={trigram}
                        checked={lowerTrigram === trigram}
                        onChange={(e) => handleLowerTrigramChange(e.target.value as Trigram)}
                        className="hidden"
                      />
                      <span>{trigram}</span>
                    </label>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">
              动爻
            </label>
            <div className="space-y-3">
              {yaoRows.map((row, rowIndex) => (
                <div key={`yao-${rowIndex}`} className="grid grid-cols-3 gap-3">
                  {row.map((position) => (
                    <label
                      key={position}
                      className={clsx(
                        'flex items-center justify-center p-3 rounded-lg cursor-pointer transition-colors',
                        movingYao === position
                          ? 'bg-red-600 text-white'
                          : 'bg-white text-gray-600 border border-gray-200 hover:bg-gray-50'
                      )}
                    >
                      <input
                        type="radio"
                        name="movingYao"
                        value={position}
                        checked={movingYao === position}
                        onChange={(e) => handleMovingYaoChange(e.target.value as YaoPosition)}
                        className="hidden"
                      />
                      <span>{position}</span>
                    </label>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={handleManualDivination}
            disabled={!upperTrigram || !lowerTrigram || !movingYao}
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-gray-400"
          >
            开始起卦
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6 mt-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">时间起卦</h2>
        <button
          onClick={handleTimeDivination}
          className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          时间起卦
        </button>
      </div>
    </div>
  );
}