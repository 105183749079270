import type { Trigram, YaoPosition } from '../types/iching';
import { logger } from './clientLogger';

// 八卦二进制映射
const trigramToBinary: Record<Trigram, string> = {
  '乾': '111',
  '兑': '011',
  '离': '101',
  '震': '001',
  '巽': '110',
  '坎': '010',
  '艮': '100',
  '坤': '000'
};

// 爻位映射到数组索引
const yaoPositionToIndex: Record<YaoPosition, number> = {
  '初爻': 0,
  '二爻': 1,
  '三爻': 2,
  '四爻': 3,
  '五爻': 4,
  '上爻': 5
};

// 计算本卦
export function calculateMainHexagram(upperTrigram: Trigram, lowerTrigram: Trigram): string {
  logger.info('Calculating main hexagram', { upperTrigram, lowerTrigram });
  const result = trigramToBinary[upperTrigram] + trigramToBinary[lowerTrigram];
  logger.info('Main hexagram calculated', { result });
  return result;
}

// 计算变卦
export function calculateChangedHexagram(hexagram: string, movingYao: YaoPosition): string {
  logger.info('Calculating changed hexagram', { hexagram, movingYao });
  
  const yaoIndex = yaoPositionToIndex[movingYao];
  const hexArray = hexagram.split('');
  // 从右往左数第yaoIndex位进行变换
  hexArray[5 - yaoIndex] = hexArray[5 - yaoIndex] === '1' ? '0' : '1';
  const result = hexArray.join('');
  
  logger.info('Changed hexagram calculated', { result });
  return result;
}

// 计算互卦
export function calculateInterlinkedHexagrams(
  hexagram: string,
  movingYao: YaoPosition
): { useHexagram: string; bodyHexagram: string } {
  logger.info('Calculating interlinked hexagrams', { hexagram, movingYao });
  
  const yaoIndex = yaoPositionToIndex[movingYao];
  const hexArray = hexagram.split('');
  
  // 根据动爻位置确定互卦取位
  const isLowerYao = yaoIndex <= 2;
  const bodyStart = isLowerYao ? 1 : 2;
  const useStart = isLowerYao ? 2 : 1;
  
  // 取三位爻组成互卦
  const bodyHexagram = hexArray.slice(bodyStart, bodyStart + 3).join('');
  const useHexagram = hexArray.slice(useStart, useStart + 3).join('');
  
  const result = { useHexagram, bodyHexagram };
  logger.info('Interlinked hexagrams calculated', result);
  return result;
}