import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import HexagramDisplay from '../components/HexagramDisplay';
import { simplifiedHexagramNames } from '../data/hexagramNames';
import { earlyHeavenTrigrams } from '../data/earlyHeavenTrigrams';
import clsx from 'clsx';

// 先天八卦顺序
const orderedTrigrams = ['111', '011', '101', '001', '110', '010', '100', '000'] as const;

// 六十四卦顺序
const orderedHexagrams = [
  '111111', '000000', '010001', '100010', '010111', '111010', '000010', '010000', 
  '110111', '111011', '000111', '111000', '111101', '101111', '000100', '001000', 
  '011001', '100110', '000011', '110000', '101001', '100101', '100000', '000001', 
  '111001', '100111', '100001', '011110', '010010', '101101', '011100', '001110', 
  '111100', '001111', '101000', '000101', '110101', '101011', '010100', '001010', 
  '100011', '110001', '011111', '111110', '011000', '000110', '011010', '010110', 
  '011101', '101110', '001001', '100100', '110100', '001011', '001101', '101100', 
  '110110', '011011', '110010', '010011', '110011', '001100', '010101', '101010'
] as const;

function chunkArray<T>(arr: readonly T[], size: number): T[][] {
  return Array.from(
    { length: Math.ceil(arr.length / size) },
    (_, i) => Array.from(arr.slice(i * size, i * size + size))
  );
}

export default function IChingLibrary() {
  const navigate = useNavigate();
  const [showEarlyHeaven, setShowEarlyHeaven] = useState(true);

  const earlyHexagramRows = chunkArray(orderedTrigrams, 4);
  const fullHexagramRows = chunkArray(orderedHexagrams, 4);

  const renderEarlyHexagramCard = (binary: string) => (
    <div
      key={binary}
      onClick={() => navigate(`/trigram/${binary}`)}
      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer p-4 flex flex-col items-center"
    >
      <HexagramDisplay 
        binary={binary} 
        size="small"
        showThreeLines={true}
      />
      <span className="mt-2 text-sm font-bold text-gray-900">
        {earlyHeavenTrigrams[binary as keyof typeof earlyHeavenTrigrams].name}
      </span>
    </div>
  );

  const renderFullHexagramCard = (binary: string) => (
    <div
      key={binary}
      onClick={() => navigate(`/hexagram/${binary}`)}
      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer p-4 flex flex-col items-center"
    >
      <HexagramDisplay 
        binary={binary} 
        size="small"
        showThreeLines={false}
      />
      <span className="mt-2 text-sm font-bold text-gray-900">
        {simplifiedHexagramNames[binary]}
      </span>
    </div>
  );

  return (
    <div className="py-6">
      <div className="mb-8 flex space-x-3 overflow-x-auto pb-2">
        <button
          onClick={() => setShowEarlyHeaven(true)}
          className={clsx(
            'px-6 py-2.5 rounded-full text-sm font-medium whitespace-nowrap transition-colors',
            showEarlyHeaven
              ? 'bg-red-600 text-white shadow-sm'
              : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-200'
          )}
        >
          先天八卦
        </button>
        <button
          onClick={() => setShowEarlyHeaven(false)}
          className={clsx(
            'px-6 py-2.5 rounded-full text-sm font-medium whitespace-nowrap transition-colors',
            !showEarlyHeaven
              ? 'bg-red-600 text-white shadow-sm'
              : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-200'
          )}
        >
          六十四卦
        </button>
      </div>

      {showEarlyHeaven ? (
        <div className="grid grid-cols-4 gap-4">
          {earlyHexagramRows.flat().map(binary => renderEarlyHexagramCard(binary))}
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-4">
          {fullHexagramRows.flat().map(binary => renderFullHexagramCard(binary))}
        </div>
      )}
    </div>
  );
}