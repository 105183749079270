import dayjs from 'dayjs';
import { LogLevel, LoggerConfig, ILogger, DEFAULT_CONFIG } from './loggerTypes';

class ClientLogger implements ILogger {
  private config: LoggerConfig;
  private worker: Worker | null = null;

  constructor(config: Partial<LoggerConfig> = {}) {
    this.config = { ...DEFAULT_CONFIG, ...config };
    this.initWorker();
  }

  private initWorker() {
    const workerBlob = new Blob([`
      self.onmessage = function(e) {
        const { level, message } = e.data;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/api/logs', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({ level, message }));
      }
    `], { type: 'application/javascript' });

    try {
      this.worker = new Worker(URL.createObjectURL(workerBlob));
    } catch (error) {
      console.error('Failed to initialize logging worker:', error);
    }
  }

  private getLogLevelPriority(level: LogLevel): number {
    const priorities: Record<LogLevel, number> = {
      off: 0,
      error: 1,
      warning: 2,
      info: 3
    };
    return priorities[level];
  }

  private shouldLog(messageLevel: LogLevel): boolean {
    return this.getLogLevelPriority(messageLevel) <= this.getLogLevelPriority(this.config.level);
  }

  private formatMessage(level: LogLevel, message: string, data?: any): string {
    const timestamp = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const dataString = data ? `\nData: ${JSON.stringify(data, null, 2)}` : '';
    return `[${timestamp}] [${level.toUpperCase()}] ${message}${dataString}`;
  }

  private writeToServer(level: LogLevel, formattedMessage: string) {
    if (this.worker) {
      this.worker.postMessage({ level, message: formattedMessage });
    }
  }

  setLevel(level: LogLevel) {
    this.config.level = level;
  }

  info(message: string, data?: any) {
    if (this.shouldLog('info')) {
      const formattedMessage = this.formatMessage('info', message, data);
      if (this.config.enableConsole) {
        console.log(formattedMessage);
      }
      this.writeToServer('info', formattedMessage);
    }
  }

  warning(message: string, data?: any) {
    if (this.shouldLog('warning')) {
      const formattedMessage = this.formatMessage('warning', message, data);
      if (this.config.enableConsole) {
        console.warn(formattedMessage);
      }
      this.writeToServer('warning', formattedMessage);
    }
  }

  error(message: string, error?: Error | any) {
    if (this.shouldLog('error')) {
      const formattedMessage = this.formatMessage('error', message, {
        error: error instanceof Error ? {
          message: error.message,
          stack: error.stack
        } : error
      });
      if (this.config.enableConsole) {
        console.error(formattedMessage);
      }
      this.writeToServer('error', formattedMessage);
    }
  }
}

export const logger = new ClientLogger();